import Flickity from 'flickity-fade'
import 'flickity-as-nav-for/as-nav-for'
import "regenerator-runtime/runtime";
import inView from 'in-view'
import { gsap } from "gsap";
import Choices from "choices.js/src/scripts/choices";

const HTML = document.querySelector('html');

class App {
    constructor() {
        // Menu dropdown mobile
        let menuPrimary = document.querySelector('.menu-primary');
        let dropdown = document.querySelectorAll('.dropdown');
        if( dropdown ) {
            dropdown.forEach((el) => {
                el.querySelector('a').addEventListener('click', (e) => {
                    if (window.innerWidth < 1024) {
                        e.preventDefault();

                        menuPrimary.classList.add('show-megamenu');
                        el.classList.add('show');
                    }
                });

                let close = el.querySelector('.megamenu__back');
                if( close ) {
                    close.addEventListener('click', (e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        menuPrimary.classList.remove('show-megamenu')
                        el.classList.remove('show')
                    })
                }
            })
        }

        // Fix menu
        // let prevScrollpos = window.pageYOffset;
        // let mainHeader = document.querySelector('.main-header')
        let mainNav = document.querySelector('.main-nav')
        // let pos = mainHeader.getBoundingClientRect().top + prevScrollpos;

        var lastScrollTop = 0;

        // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
        window.addEventListener("scroll", function(){ // or window.addEventListener("scroll"....
            var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
            if (st > lastScrollTop || st < 200 ){
                mainNav.classList.remove('fixed');
                if ( st > 200 ) {
                    HTML.classList.add('hide-header');
                }
                // HTML.classList.remove('hide-header');
            } else {
                mainNav.classList.add('fixed');
                HTML.classList.remove('hide-header');
                // HTML.classList.add('hide-header');
            }
            lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        }, false);

        // Burger menu
        let burger = document.querySelector('.menu-burger');
        let closeBurger = document.querySelector('.close-burger');
        if( burger ) {
            burger.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                HTML.classList.remove('show-sidebar', '--cart');
                let sideBar = document.querySelector('.sidebar.show');
                if(sideBar)
                    sideBar.classList.remove('show');
                HTML.classList.add('open-menu')
                HTML.classList.remove('hide-header')
            })
            closeBurger.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                let delay = menuPrimary.classList.contains('show-megamenu') ? 500 : 0;

                if( dropdown ) {
                    dropdown.forEach((el) => {
                        let close = el.querySelector('.megamenu__back');
                        if( close ) close.dispatchEvent(new Event('click'))
                    })
                }

                setTimeout(() => {
                    HTML.classList.remove('open-menu')
                }, delay);
            })
        }

        // In view
        inView('.js-reveal:not(.is-visible)').on('enter', (el) => {
            el.classList.add('is-visible');
            el.dispatchEvent(new Event('inviewport'));
        });

        this.init();

        return this;
    }

    /**
     * Init the page
     * @return {object} The current instance
     */
    init() {
        this.lazyLoadingFigures();
        this.lazyLoading();
        this.carouselMain();
        this.generateCarousel('.cross-selling__content');
        this.sidebar();
        this.initFilters();
        this.loadCatalogue();
        this.tabs();
        this.alertsRegister();
        this.newsletterInit();
        this.ytEmbedBuilder();
        this.monthsNav();
        this.genresNav();
        this.collectionsNav();
        this.textContrastImg();
        this.searchAutoComplete();
        this.searchPage();
        this.selectOptions();
        this.loadMore();
        this.readMore();
        this.initAddToCart();
        this.initCartQtyBtns();
        this.initBdPlayer();

        return this;
    }

    /**
     * Init homepage carousel
     */
    carouselMain() {
        let carousels = document.querySelectorAll('.carousel-main');

        if (carousels) {
            carousels.forEach((el) => {
                let current = 0;
                let cells_container = el.querySelector('.carousel-main__cell');
                let cells = el.querySelectorAll('.carousel-main__content');
                let backgrounds = el.querySelectorAll('.carousel-main__bgs figure');
                
                let flkty = new Flickity(cells_container, {
                    lazyLoad: 3,
                    wrapAround: true,
                    fade : true,
                    adaptiveHeight: true,
                    initialIndex: -1,
                    on : {
                        ready : () => {
                            // Init reader bd
                            if (backgrounds[0]) {
                                backgrounds[0].classList.add('active');
                            }
                        },
                        change: (index) => {
                            let cElt = cells[current]
                            let cBg = backgrounds[current]
                            cElt.classList.add('back')

                            if (cBg) {
                                cBg.classList.add('hide')
                            }
                            if (backgrounds[index]) {
                                backgrounds[index].classList.add('active');
                            }

                            setTimeout(() => {
                                cElt.classList.remove('back')
                                if (cBg) {
                                    cBg.classList.remove('hide', 'active')
                                }
                            }, 410)

                            current = index;
                        }
                    }
                });
            });
        }
    }

    /**
     * Init carousels
     */
    generateCarousel(selector) {
        let carousel = document.querySelectorAll(selector)

        if (carousel) {
            carousel.forEach((el) => {
                let scrollbar = document.createElement('div')
                scrollbar.classList.add('carousel__scrollbar')
                let bar = document.createElement('div')
                bar.classList.add('bar')
                scrollbar.appendChild(bar);

                scrollbar.addEventListener('click', (e) => {
                    e.preventDefault();
                    let w = scrollbar.offsetWidth;
                    let x = e.clientX - scrollbar.getBoundingClientRect().x;
                    flkty.select(Math.round(((x/w) * flkty.slideableWidth) / flkty.slides[0].outerWidth));
                });

                let flkty = new Flickity( el, {
                    contain: true,
                    cellAlign: 'center',
                    prevNextButtons: false,
                    lazyLoad: 4,
                    adaptiveHeight: false,
                    watchCss : true,
                    on : {
                        ready : function() {
                            setTimeout(() => {
                                window.dispatchEvent(new Event('resize'))
                            }, 100);

                            if(this.size.width <= this.slideableWidth)
                                el.appendChild(scrollbar)
                        },
                        scroll : function(event, progress) {
                            bar.style.width = ((progress + this.size.width) / this.slideableWidth) * 100 + '%';
                        }
                    }
                })
            })
        }
    }

    /**
     * Lazyload images
     */
    lazyLoading() {
        let lazy_img = document.querySelectorAll('img.js-lazy:not(.lazy-loaded)');

        if (lazy_img) {
            let trigger = () => {
                let i = 0;
                lazy_img.forEach(async (el) => {
                    if (el.dataset.hidem) {
                        if (window.innerWidth >= 1280) { // Hide none
                            el.classList.contains('no-lazy') ? el.classList.remove('no-lazy') : '';
                        } else if (window.innerWidth >= 992) { // Hide 2
                            el.classList.contains('no-lazy') && i < 4 ? el.classList.remove('no-lazy') : el.classList.add('no-lazy');
                        } else if (window.innerWidth >= 768) { // Hide 4
                            el.classList.contains('no-lazy') && i < 2 ? el.classList.remove('no-lazy') : el.classList.add('no-lazy');
                        } else { // Hide all
                            el.classList.add('no-lazy');
                        }

                        i++;
                    }

                    if (el.classList.contains('lazy-loaded') || el.classList.contains('no-lazy')) {
                        return;
                    } else {
                        let bcr = el.getBoundingClientRect();
                        
                        if (bcr.top < window.innerHeight) {
                            const imgLoadPromise = new Promise((resolve) => {
                                let img = new Image();
                                img.onload = () => {
                                    el.src = img.src;
                                    el.classList.add('lazy-loaded');
            
                                    if (el.closest('figure')) {
                                        el.closest('figure').classList.add('img-is-loaded');
                                    }
                                    
                                    delete el.dataset.src;

                                    if (el.parentElement.querySelector('.to_del_lazy')) {
                                        gsap.to(el.parentElement.querySelector('.to_del_lazy'), { duration: 0.25, autoAlpha: 0, display: 'none', ease: "cubic.inOut" });
                                        setTimeout(() => {
                                            if (el.parentElement.querySelector('.to_del_lazy')) {
                                                el.parentElement.querySelector('.to_del_lazy').remove();
                                            } else {
                                                return;
                                            }
                                        }, 250);
                                    }
                                }
                                img.src = el.dataset.src;
                            });

                            await imgLoadPromise;
                        }
                    }
                });
            }
            
            trigger();

            window.addEventListener('scroll', trigger);
            window.addEventListener('resize', trigger);
        }
    }

    /**
     * Lazyload images from figures
     */
    lazyLoadingFigures() {
        let lazy = document.querySelectorAll('figure.js-lazy:not(.lazy-loaded)');

        if (lazy) {
            lazy.forEach(async (el) => {
                let img;
                const figureLoadPromise = new Promise((resolve) => {
                    img = new Image();
                    img.onload = () => {
                        el.src = img.src;
                        el.classList.add('lazy-loaded');
                        el.style.backgroundImage = 'url("'+img.src+'")';
                    }
                    img.src = el.dataset.src;
                });
    
                await figureLoadPromise;
            });
        }
    }

    /**
     * Init filters
     */
    initFilters() {
        let filters = document.querySelectorAll('.list__filters');

        if (filters) {
            filters.forEach((el) => {
                let filter = el.querySelectorAll('._filter');
               
                if (filter) {
                    filter.forEach((elm) => {
                        
                        elm.addEventListener('click', (e) => {
                            e.preventDefault();
                            if (!elm.classList.contains('active')) { // Block after the first click to avoid spamming requests
                                // Remove active class
                                let active = elm.parentElement.querySelector('._filter.active');
                                active.classList.remove('active');

                                // Add active class
                                elm.classList.add('active');

                                if (location.hostname == 'action.spirou.com') {
                                    var url = '/vega/catalogue/filtres/genres/'+elm.dataset.genre;
                                } else {
                                    var url = '/catalogue/filtres/genres/'+elm.dataset.genre;
                                }

                                let xhr = new XMLHttpRequest();
                                xhr.onreadystatechange = () => {
                                    if (xhr.readyState == 4 && xhr.status == 200) {
                                        let response = JSON.parse(xhr.response);

                                        if (response.content) {
                                            let container = document.querySelector('._new_alb .list__product')
                                            let container_parent = container.parentElement;
    
                                            container.remove();
                                            container_parent.innerHTML = response.content;
    
                                            this.loadCatalogue();
                                            this.lazyLoading();
                                        } else {
                                            return;
                                        }
                                    }
                                };
                                xhr.open('GET', url, true);
                                xhr.send();
                            } else {
                                return;
                            }
                        });
                    });
                }
            });
        }
    }

    /**
     * Load product lists
     */
    loadCatalogue(data) {
        let items_list = document.querySelectorAll('.js_rCat:not(.-show)');
            
        if (items_list) {
            items_list.forEach((el, index) => {
                setTimeout(() => {
                    el.classList.add('-show');
                }, 100 * index + 1);
            });
        }
    }

    /**
     * Create tabs
     */
    tabs() {
        let tabs = document.querySelectorAll('.tabs');

        if( tabs ) {
            let initialClick = 0;

            tabs.forEach((el) => {
                let current = -1;
                let container = el.querySelector('.tabs__container')
                let list = el.querySelectorAll('.tabs__list li')
                let cursor = el.querySelector('.cursor');
                let items = el.querySelectorAll('.tabs__item')
                let btnShowContent = el.querySelector('.show-content')

                list.forEach((l) => {
                    l.addEventListener('click', (e) => {
                        e.preventDefault();

                        if( l.classList.contains('active')) return;
                        l.classList.add('active')

                        let index = [...l.parentElement.children].indexOf(l);

                        let dir = index > current ? 1 : -1;

                        if( current >= 0 ) {
                            list[current].classList.remove('active')
                            gsap.to(items[current], { duration : 0.6, x : (dir * -30) +'%', autoAlpha : 0, ease : "cubic.inOut" })
                        }

                        gsap.fromTo(items[index], { x : (dir * 30) +'%', autoAlpha : 0 }, { duration : 0.6, x : '0%', autoAlpha : 1, ease : "cubic.out", delay : 0.3});

                        current = index;

                        let pos = l.getBoundingClientRect();
                        let posParent = l.parentNode.getBoundingClientRect();
                        gsap.to(cursor, { duration : 0.3, width : `${l.offsetWidth}px`, x : pos.x - posParent.x, ease : 'cubic.inOut'})

                        window.dispatchEvent(new Event('resize'))

                        if (initialClick > 1) {
                            let header_offset = 100;
                            let el_main_parent = l.closest('#resume');
                            if (el_main_parent) {
                                let element_pos = el_main_parent.offsetTop;
                                let offset_pos = element_pos - header_offset;
    
                                window.scrollTo({
                                    top: offset_pos,
                                    behavior: 'smooth'
                                });
                            }
                        }

                        initialClick++;
                    })
                })

                if(btnShowContent)
                    btnShowContent.addEventListener('click', (e) => {
                        setTimeout(function () {
                            window.dispatchEvent(new Event('resize'))
                        }, 200)
                    })

                window.addEventListener('resize', () => {
                    if (items[current].offsetHeight < 250) {
                        gsap.to(container, { duration: 0.2, height : items[current].offsetHeight, minHeight: 340, delay : 0.001 });
                    } else {
                        gsap.to(container, { duration: 0.2, height : items[current].offsetHeight,  delay : 0.001 });
                    }
                    
                })

                if (document.body.dataset.lang == 'NL') {
                    if (list[0].closest('#resume')) {
                        list[1].dispatchEvent(new Event('click'));
                    } else {
                        list[0].dispatchEvent(new Event('click'));
                    }
                } else {
                    list[0].dispatchEvent(new Event('click'));
                }
            })
        }
    }

    /**
     * Init alerts register form
     */
    alertsRegister() {
        let btn_alert = document.querySelector('#_alert');

        if (btn_alert) {
            btn_alert.addEventListener('click', (e) => {
                e.preventDefault();

                let data_id = btn_alert.dataset.id;
                let data_type = btn_alert.dataset.type;

                let form_alert_container = document.createElement('div');
                form_alert_container.classList.add('container');
                form_alert_container.classList.add('container__alert');
                
                let form_alert_form = document.createElement('form');
                form_alert_form.classList.add('container__alert--form');

                let form_alert_form_input = document.createElement('input');
                form_alert_form_input.type = 'email';
                form_alert_form_input.name = 'email';
                form_alert_form_input.placeholder = 'exemple@email.com';
                form_alert_form_input.id = 'email_alerts';

                let form_alert_form_submit = document.createElement('button');
                form_alert_form_submit.classList.add('btn');
                form_alert_form_submit.classList.add('-darkred');
                form_alert_form_submit.id = 'sub_alerts';
                form_alert_form_submit.innerText = 'Ok';

                let form_alert_form_err = document.createElement('small');
                form_alert_form_err.classList.add('container__alert--form-error');
                form_alert_form_err.classList.add('animate');

                form_alert_form.appendChild(form_alert_form_input);
                form_alert_form.appendChild(form_alert_form_submit);
                form_alert_form.appendChild(form_alert_form_err);
                form_alert_container.appendChild(form_alert_form);
                btn_alert.parentElement.appendChild(form_alert_container);

                gsap.to(btn_alert, {opacity: 0, duration: .25, ease: 'power4.inOut'});
                gsap.to(form_alert_container, {opacity: 1, duration: .25, ease: 'power4.inOut'});

                this.subscribeAlerts(data_id, data_type, form_alert_form_input, form_alert_form_submit, form_alert_form_err);
            });
        }
    }

    /**
     * Init newsletter form (footer)
     */
    newsletterInit() {
        let newsletter_form = document.querySelector('._addNwsltr');

        if (newsletter_form) {
            let email = newsletter_form.querySelector('#newsletter_email');
            let submit_btn_nl = newsletter_form.querySelector('.btn');
            let err_nl = newsletter_form.querySelector('._errNl');

            this.subscribeAlerts('0', 'newsletter', email, submit_btn_nl, err_nl);
        }
    }

    /**
     * Subscribe user to alert concerning the id & type
     * Or to newsletter if the type is newsletter
     * 
     * @param {Integer|String} id // This will be a string only for the newsletter
     * @param {String} type
     * @param {Object} email_input
     * @param {Object} submit_btn
     * @param {Object} err
     */
    subscribeAlerts(id, type, email_input, submit_btn, err) {
        if (id && type && email_input && submit_btn && err) {
            let email_empty = 0;
            let email_invalid = 0;

            submit_btn.addEventListener('click', (e) => {
                e.preventDefault();

                // Front error first check
                if (email_empty === 1) {
                    err.classList.remove('shakeX');
                    setTimeout(() => {
                        err.classList.add('shakeX');
                    }, 1);
                    email_empty = 0;
                } else {
                    if (!email_input.value) {
                        err.innerHTML = 'Veuillez indiquer une adresse mail.';
                        gsap.to(err, {x: 0, opacity: 1, duration: .45, ease: 'power4.inOut'});
                        email_empty = 1;
                    }
                }

                if (email_invalid === 1) {
                    if (email_input.value && /(^\w.*@\w+\.\w)/.test(email_input.value)) {
                        var email_to_subscribe = email_input.value;
                    } else {
                        err.classList.remove('shakeX');
                        setTimeout(() => {
                            err.classList.add('shakeX');
                        }, 1);
                        email_invalid = 0;
                    }
                } else {
                    if (email_input.value && !/(^\w.*@\w+\.\w)/.test(email_input.value)) {
                        err.innerHTML = 'Veuillez indiquer une adresse mail valide.';
                        gsap.to(err, {x: 0, opacity: 1, duration: .45, ease: 'power4.inOut'});
                        email_invalid = 1;
                    } else {
                        var email_to_subscribe = email_input.value;
                    }
                }

                // there is an email -> validate it with php now
                if (email_to_subscribe) {
                    // We reset all error management
                    err.innerHTML = '';
                    email_empty = 0;
                    email_invalid = 0;

                    // Create loader
                    let loader_container = document.createElement('div');
                    loader_container.classList.add('container__loader');

                    let loader = document.createElement('div');
                    loader.classList.add('loader');

                    let emptyDiv = document.createElement('div');
                    [1,2,3,4].forEach(() => {
                        loader.appendChild(emptyDiv.cloneNode(true));
                    });

                    loader_container.appendChild(loader);
                    email_input.parentElement.appendChild(loader_container);

                    if (type === 'newsletter' && id === '0') {
                        if (location.hostname == 'action.spirou.com') {
                            var url = '/vega/add_newsletter';
                        } else {
                            var url = '/add_newsletter';
                        }
        
                        let xhr = new XMLHttpRequest();
                        xhr.onreadystatechange = () => {
                            if (xhr.readyState == 4 && xhr.status == 200) {
                                let response = JSON.parse(xhr.response);
        
                                setTimeout(() => {
                                    loader_container.remove();
                                }, 250);
                                
                                if (response.ok) {
                                    console.log(response);
                                    let form = email_input.parentElement;
                                    let main_container = form.parentElement;

                                    gsap.to(form, {opacity: 0, duration: .25, ease: 'power4.inOut'});

                                    let success_message = document.createElement('span');
                                    success_message.innerText = response.ok_message;
                                    success_message.classList.add('success');
    
                                    main_container.appendChild(success_message);
    
                                    gsap.to(success_message, {opacity: 1, duration: .25, ease: 'power4.inOut'});
                                } else {
                                    console.log('error_nl');
                                }
                            }
                        };
                        xhr.open('POST', url, true);
                        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                        xhr.send('email='+encodeURIComponent(email_to_subscribe));
                    } else {
                        if (location.hostname == 'action.spirou.com') {
                            var url = '/vega/DFffdsgdfd5hd9521dgf9h4d6dg5h4_';
                        } else {
                            var url = '/DFffdsgdfd5hd9521dgf9h4d6dg5h4_';
                        }
    
                        let xhr = new XMLHttpRequest();
                        xhr.onreadystatechange = () => {
                            if (xhr.readyState === 4 && xhr.status === 200) {
                                let response = JSON.parse(xhr.response);
    
                                setTimeout(() => {
                                    loader_container.remove();
                                }, 250);
    
                                if (response.ok) {
                                    let main_container = email_input.closest('.product__soon');
                                    let form = email_input.parentElement;
    
                                    gsap.to(form, {opacity: 0, duration: .25, ease: 'power4.inOut'});
    
                                    let success_message = document.createElement('span');
                                    success_message.innerText = response.ok_message;
                                    success_message.classList.add('product__soon--success');
    
                                    main_container.appendChild(success_message);
    
                                    gsap.to(success_message, {opacity: 1, duration: .25, ease: 'power4.inOut'});
                                } else {
                                    // Gestion d'erreur
                                }
                            }
                        }
                        xhr.open('POST', url, true);
                        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                        xhr.send(
                            'email='+encodeURIComponent(email_to_subscribe)+
                            '&type='+encodeURIComponent(type)+
                            '&id='+encodeURIComponent(id)
                        );
                    }
                }
            });
        }
    }

    /**
     * Init BD player
     */
    initBdPlayer() {
        let reader_btns = document.querySelectorAll('._bdR');

        if (reader_btns) {
            reader_btns.forEach((el) => {
                if (el.dataset.listener === true) {
                    return;
                } else {
                    el.dataset.listener = true;
                    el.addEventListener('click', (e) => {
                        e.preventDefault();
    
                        this._bdreader_builder(el.dataset.token);
                    });
                }

                if (location.hash == '#lecteurbd' || location.hash == '#lecteurmanga') {
                    setTimeout(() => {
                        el.dispatchEvent(new Event('click'));
                    }, 1250);
                }
            });
        }
    }

    /**
     * Build the html for the bd reader
     * 
     * @param {String} token 
     */
     _bdreader_builder(token) {
        let iframeModal = document.createElement('div');
        iframeModal.classList.add('iframe__modal');

        let iframeElement = document.createElement('iframe');
        iframeElement.src = 'https://www.westory.fr/player-display?do=html&token='+token;
        iframeElement.setAttribute('width', '100%');
        iframeElement.setAttribute('height', '100%');
        iframeElement.setAttribute('frameborder', 0);
        iframeElement.setAttribute('scrolling', 'no');
        iframeElement.setAttribute('allowfullscreen', '');

        let iframeQuit = document.createElement('span');
        iframeQuit.classList.add('close');
        iframeQuit.setAttribute('onclick', 'window.closeIframe(this);');
        iframeQuit.innerHTML = '&nbsp;Retour au site';

        let iframeQuitIcon = document.createElement('span');
        iframeQuitIcon.classList.add('icon-gauche');
        iframeQuitIcon.innerHTML = '&#8249;';

        let iframeLoader = document.createElement('div');
        iframeLoader.classList.add('loader');
        iframeQuit.appendChild(iframeQuitIcon);
        iframeModal.appendChild(iframeElement);
        iframeModal.appendChild(iframeQuit);
        iframeModal.appendChild(iframeLoader);

        document.body.appendChild(iframeModal);

        setTimeout(() => {
            iframeModal.classList.add('show');
        }, 250);

        iframeElement.addEventListener('load', () => {
            setTimeout(() => {
                iframeLoader.classList.add('hide');
                iframeLoader.style.display = 'none';
            }, 250);
        });
    }

    /**
     * Create youtube embed iframe
     */
    ytEmbedBuilder() {
        let players = document.querySelectorAll('.yt_player');

        if (players) {
            players.forEach((el) => {
                let url = el.dataset.url.split('?v=')[1];

                let video_embed = document.createElement('iframe');
                video_embed.src = 'https://www.youtube-nocookie.com/embed/'+url;
                video_embed.setAttribute('frameborder', 0);
                video_embed.setAttribute('scrolling', 'no');
                video_embed.setAttribute('allowfullscreen', '');

                el.appendChild(video_embed);
                setTimeout(() => {
                    el.classList.add('show');
                }, 250)
            });
        }
    }

    /**
     * Init monthly ajax navigation
     */
    monthsNav() {
        let navigation = document.querySelector('.n_months');
        let _context = this;

        if (navigation) {
            let month_btn = navigation.querySelectorAll('.n_months_btn');
            let prev_month = navigation.querySelector('.n_months_prev');
            let next_month = navigation.querySelector('.n_months_next');

            // Listen to keyboard arrows
            document.addEventListener('keyup', (ev) => {
                ev.preventDefault();
                
                switch (ev.code) {
                    case 'ArrowLeft':
                        prev_month.dispatchEvent(new Event('click'));
                        break;
                    case 'ArrowRight':
                        next_month.dispatchEvent(new Event('click'));
                        break;
                }
            });

            // Need to make an ajax call on popstates too
            window.addEventListener('popstate', (evt) => {
                //console.log(evt);
                if (evt.isTrusted === true && evt.state !== null) {
                    let evt_state = evt.state;
                    let date = evt_state.split('-');
                    let month = date[0];
                    let year = date[1];

                    let prev_next = 'popstate';

                    if (location.hostname == 'action.spirou.com') {
                        var url = '/vega/catalogue/planning/'+month+'-'+year+'/'+prev_next;
                    } else {
                        var url = '/catalogue/planning/'+month+'-'+year+'/'+prev_next;
                    }

                    let xhr = new XMLHttpRequest();
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState == 4 && xhr.status == 200) {
                            let response = JSON.parse(xhr.response);
                            responseHandler(response);
                        }
                    };
                    xhr.open('GET', url, true);
                    xhr.send();
                }
            });

            if (month_btn) {
                month_btn.forEach((el) => {
                    el.addEventListener('click', (e) => {
                        e.preventDefault();

                        let data_date = document.querySelector('.n_months .data_date');
                        let active_month = data_date.dataset.month;
                        let active_year = data_date.dataset.year;

                        if (location.hostname == 'action.spirou.com') {
                            var url = '/vega/catalogue/planning/'+el.dataset.month+'/'+active_month+'/'+active_year;
                        } else {
                            var url = '/catalogue/planning/'+el.dataset.month+'/'+active_month+'/'+active_year;
                        }

                        let xhr = new XMLHttpRequest();
                        xhr.onreadystatechange = () => {
                            if (xhr.readyState == 4 && xhr.status == 200) {
                                let response = JSON.parse(xhr.response);

                                console.log(response);
                                if (location.hostname == 'action.spirou.com') {
                                    var new_url = '/vega/catalogue/planning/'+response.new_active_month+'-'+response.new_active_year;
                                } else {
                                    var new_url = '/catalogue/planning/'+response.new_active_month+'-'+response.new_active_year;
                                }
                                
                                // Change the url
                                window.history.pushState(response.new_active_month+'-'+response.new_active_year, 'Vega - '+new_url, new_url);

                                responseHandler(response);
                            }
                        };
                        xhr.open('GET', url, true);
                        xhr.send();
                    });
                });
            }

            // Handles the ajax response
            function responseHandler(response) {
                let container_date = document.querySelector('.n_months .data_date');
                let container = document.querySelector('.list__product');
                let container_parent = container.parentElement;

                container_date.dataset.month = response.new_active_month;
                container_date.dataset.year = response.new_active_year;
                container_date.innerText = response.new_active_date_m_y;

                container.remove();
                container_parent.innerHTML = response.content;

                if (response.no_albums === true) {
                    setTimeout(() => {
                        container_parent.querySelector('.list__product').firstElementChild.classList.add('is-visible');
                    }, 1);
                } else {
                    _context.loadCatalogue();
                    _context.lazyLoading();
                }
            }
        }
    }

    /**
     * Init genre ajax navigation
     */
    genresNav() {
        let navigation = document.querySelector('.n_genres');
        let navigation_tab = document.querySelector('.n_s_genres');

        if (navigation) {
            let genres_btn = navigation.querySelectorAll('.n_genres_btn');

            if (genres_btn) {
                let genre = document.querySelector('.n_m_genre');
                let genre_desc = document.querySelector('.n_m_genre_desc');
                let genre_left = document.querySelector('button[data-direction=left]');
                let genre_right = document.querySelector('button[data-direction=right]');
                let genre_tab = document.querySelector('.n_s_genres');

                // Listen to keyboard arrows
                document.addEventListener('keyup', (ev) => {
                    ev.preventDefault();
                    
                    switch (ev.code) {
                        case 'ArrowLeft':
                            genre_left.dispatchEvent(new Event('click'));
                            break;
                        case 'ArrowRight':
                            genre_right.dispatchEvent(new Event('click'));
                            break;
                    }
                });

                // Need to make an ajax call on popstates too
                window.addEventListener('popstate', (evt) => {
                    //console.log(evt);
                    if (evt.isTrusted === true && evt.state !== null) {
                        let data_genre = evt.state;
                        let data_old_genre = document.querySelector('.n_m_genre').dataset.genre;
                        let data_l_r = 'left'; // Isn't important here but still needs to be set to re-use the function
                        let data_a_t = document.querySelector('.n_s_genres .n_s_genres_btn.active').dataset.tab;

                        if (location.hostname == 'action.spirou.com') {
                            var url = '/vega/catalogue/genre/'+data_genre+'/1/'+data_old_genre+'/'+data_l_r+'/'+data_a_t;
                        } else {
                            var url = '/catalogue/genre/'+data_genre+'/1/'+data_old_genre+'/'+data_l_r+'/'+data_a_t;
                        }

                        let xhr = new XMLHttpRequest();
                        xhr.onreadystatechange = () => {
                            if (xhr.readyState == 4 && xhr.status == 200) {
                                let response = JSON.parse(xhr.response);
                                let container = document.querySelector('.list__product');
                                let container_parent = container.parentElement;

                                // Update the buttons
                                genre.innerText = response.genre;
                                genre.classList.remove(response.genre_class_to_remove);
                                genre.classList.add(response.genre_class);
                                genre.dataset.genre = response.genre_data;

                                genre_desc.innerText = response.genre_desc;

                                genre_left.querySelector('.txt').innerText = response.genre_left;
                                genre_left.className = '';
                                genre_left.classList.add('btn');
                                genre_left.classList.add('n_genres_btn');
                                genre_left.classList.add(response.genre_left_class);
                                genre_left.dataset.genre = response.genre_left_data;

                                genre_right.querySelector('.txt').innerText = response.genre_right;
                                genre_right.className = '';
                                genre_right.classList.add('btn');
                                genre_right.classList.add('n_genres_btn');
                                genre_right.classList.add(response.genre_right_class);
                                genre_right.dataset.genre = response.genre_right_data;

                                genre_tab.classList.remove(response.genre_class_to_remove);
                                genre_tab.classList.add(response.genre_class);

                                container.remove();
                                container_parent.innerHTML = response.content;

                                if (response.no_albums === true) {
                                    setTimeout(() => {
                                        container_parent.querySelector('.list__product').firstElementChild.classList.add('is-visible');
                                    }, 1);
                                } else {
                                    this.loadCatalogue();
                                    this.lazyLoading();
                                }
                            }
                        };
                        xhr.open('GET', url, true);
                        xhr.send();
                    }
                });

                genres_btn.forEach((el) => {
                    el.addEventListener('click', (e) => {
                        e.preventDefault();

                        let data_genre = el.dataset.genre;
                        let data_old_genre = document.querySelector('.n_m_genre').dataset.genre;
                        let data_l_r = el.dataset.direction;
                        let data_a_t = document.querySelector('.n_s_genres .n_s_genres_btn.active').dataset.tab;

                        if (data_genre === data_old_genre) {
                            return;
                        } else {
                            if (location.hostname == 'action.spirou.com') {
                                var url = '/vega/catalogue/genre/'+data_genre+'/1/'+data_old_genre+'/'+data_l_r+'/'+data_a_t;
                            } else {
                                var url = '/catalogue/genre/'+data_genre+'/1/'+data_old_genre+'/'+data_l_r+'/'+data_a_t;
                            }
    
                            let xhr = new XMLHttpRequest();
                            xhr.onreadystatechange = () => {
                                if (xhr.readyState == 4 && xhr.status == 200) {
                                    
                                    let response = JSON.parse(xhr.response);
                                    let container = document.querySelector('.list__product');
                                    let container_parent = container.parentElement;

                                    // Change the url
                                    window.history.pushState(response.genre, 'Vega - '+response.genre, response.updated_url);

                                    // Update the buttons
                                    genre.innerText = response.genre;
                                    genre.classList.remove(response.genre_class_to_remove);
                                    genre.classList.add(response.genre_class);
                                    genre.dataset.genre = response.genre_data;

                                    genre_desc.innerText = response.genre_desc;

                                    genre_left.querySelector('.txt').innerText = response.genre_left;
                                    if (response.direction === 'left') {
                                        genre_left.classList.remove(response.genre_class);
                                    }
                                    if (response.direction === 'right') {
                                        genre_left.className = '';
                                        genre_left.classList.add('btn');
                                        genre_left.classList.add('n_genres_btn');
                                    }
                                    genre_left.classList.add(response.genre_left_class);
                                    genre_left.dataset.genre = response.genre_left_data;

                                    genre_right.querySelector('.txt').innerText = response.genre_right;
                                    if (response.direction === 'left') {
                                        genre_right.className = '';
                                        genre_right.classList.add('btn');
                                        genre_right.classList.add('n_genres_btn');
                                    }
                                    if (response.direction === 'right') {
                                        genre_right.classList.remove(response.genre_class);
                                    }
                                    genre_right.classList.add(response.genre_right_class);
                                    genre_right.dataset.genre = response.genre_right_data;

                                    genre_tab.classList.remove(response.genre_class_to_remove);
                                    genre_tab.classList.add(response.genre_class);

                                    container.remove();
                                    container_parent.innerHTML = response.content;
    
                                    if (response.no_albums === true) {
                                        setTimeout(() => {
                                            container_parent.querySelector('.list__product').firstElementChild.classList.add('is-visible');
                                        }, 1);
                                    } else {
                                        this.loadCatalogue();
                                        this.lazyLoading();
                                    }
                                }
                            };
                            xhr.open('GET', url, true);
                            xhr.send();
                        }
                    });
                });
            }
        }

        if (navigation_tab) {
            let n_s_btn = document.querySelectorAll('.n_s_genres_btn');

            if (n_s_btn) {
                n_s_btn.forEach((el) => {
                    el.addEventListener('click', (e) => {
                        e.preventDefault();

                        if (el.classList.contains('active')) {
                            return;
                        } else {
                            el.parentElement.parentElement.querySelector('.active').classList.remove('active');
                            el.classList.add('active');

                            let data_tab = el.dataset.tab;
                            let data_genre = document.querySelector('.n_m_genre').dataset.genre;

                            if (location.hostname == 'action.spirou.com') {
                                var url = '/vega/catalogue/genre/'+data_genre+'/2/'+data_tab+'/tab/'+data_tab;
                            } else {
                                var url = '/catalogue/genre/'+data_genre+'/2/'+data_tab+'/tab/'+data_tab;
                            }

                            let xhr = new XMLHttpRequest();
                            xhr.onreadystatechange = () => {
                                if (xhr.readyState == 4 && xhr.status == 200) {
                                    let response = JSON.parse(xhr.response);
                                    let container = document.querySelector('.list__product');
                                    let container_parent = container.parentElement;

                                    container.remove();
                                    container_parent.innerHTML = response.content;

                                    if (response.no_albums === true) {
                                        setTimeout(() => {
                                            container_parent.querySelector('.list__product').firstElementChild.classList.add('is-visible');
                                        }, 1);
                                    } else {
                                        this.loadCatalogue();
                                        this.lazyLoading();
                                    }
                                }
                            };
                            xhr.open('GET', url, true);
                            xhr.send();
                        }
                    });
                });
            }
        }
    }

    /**
     * Init collection ajax navigation
     */
    collectionsNav() {
        console.log('collectionsNav');
        let navigation = document.querySelector('.n_genres');
        let navigation_tab = document.querySelector('.n_s_genres');

        if (navigation) {
            let collection_btn = navigation.querySelectorAll('.n_collection_btn');

            if (collection_btn) {
                let genre = document.querySelector('.n_m_genre');
                let genre_desc = document.querySelector('.n_m_genre_desc');
                let genre_left = document.querySelector('button[data-direction=left]');
                let genre_right = document.querySelector('button[data-direction=right]');
                let genre_tab = document.querySelector('.n_s_genres');

                // Listen to keyboard arrows
                document.addEventListener('keyup', (ev) => {
                    ev.preventDefault();
                    
                    switch (ev.code) {
                        case 'ArrowLeft':
                            genre_left.dispatchEvent(new Event('click'));
                            break;
                        case 'ArrowRight':
                            genre_right.dispatchEvent(new Event('click'));
                            break;
                    }
                });

                // Need to make an ajax call on popstates too
                window.addEventListener('popstate', (evt) => {
                    //console.log(evt);
                    if (evt.isTrusted === true && evt.state !== null) {
                        let data_genre = evt.state;
                        let data_old_genre = document.querySelector('.n_m_genre').dataset.genre;
                        let data_l_r = 'left'; // Isn't important here but still needs to be set to re-use the function
                        let data_a_t = 'tomes';

                        if (location.hostname == 'action.spirou.com') {
                            var url = '/vega/catalogue/collection/'+data_genre+'/1/'+data_old_genre;
                        } else {
                            var url = '/catalogue/collection/'+data_genre+'/1/'+data_old_genre;
                        }

                        let xhr = new XMLHttpRequest();
                        xhr.onreadystatechange = () => {
                            if (xhr.readyState == 4 && xhr.status == 200) {
                                let response = JSON.parse(xhr.response);
                                let container = document.querySelector('.list__product');
                                let container_parent = container.parentElement;

                                if (response.genre_left_data == 'kfactory') {
                                    response.genre_left_data = 'K Factory';
                                } else if (response.genre_right_data == 'kfactory') 
                                        response.genre_right_data = 'K Factory';

                                // Update the buttons
                                genre.innerText = response.genre;
                                genre.classList.remove(response.genre_class_to_remove);
                                console.log(genre.classList);
                                genre.classList.add(response.genre_class);
                                genre.dataset.genre = response.genre_data;

                                genre_desc.innerText = response.genre_desc;

                                genre_left.querySelector('.txt').innerText = response.genre_left;
                                genre_left.classList.remove();
                                genre_left.classList.add('btn');
                                genre_left.classList.add('n_collection_btn');
                                genre_left.classList.add(response.genre_left_class);
                                genre_left.dataset.genre = response.genre_left_data;

                                genre_right.querySelector('.txt').innerText = response.genre_right;
                                genre_right.className = '';
                                genre_right.classList.add('btn');
                                genre_right.classList.add('n_collection_btn');
                                genre_right.classList.add(response.genre_right_class);
                                genre_right.dataset.genre = response.genre_right_data;

                                genre_tab.classList.remove(response.genre_class_to_remove);
                                genre_tab.classList.add(response.genre_class);

                                container.remove();
                                container_parent.innerHTML = response.content;

                                if (response.no_albums === true) {
                                    setTimeout(() => {
                                        container_parent.querySelector('.list__product').firstElementChild.classList.add('is-visible');
                                    }, 1);
                                } else {
                                    this.loadCatalogue();
                                    this.lazyLoading();
                                }
                            }
                        };
                        xhr.open('GET', url, true);
                        xhr.send();
                    }
                });

                collection_btn.forEach((el) => {
                    el.addEventListener('click', (e) => {
                        e.preventDefault();

                        let data_genre = el.dataset.genre;
                        let data_old_genre = document.querySelector('.n_m_genre').dataset.genre;
                        let data_l_r = el.dataset.direction;
                        let data_a_t = 'tomes';

                        if (data_genre === data_old_genre) {
                            return;
                        } else {
                            if (location.hostname == 'action.spirou.com') {
                                var url = '/vega/catalogue/collection/'+data_genre+'/1/'+data_old_genre;
                            } else {
                                var url = '/catalogue/collection/'+data_genre+'/1/'+data_old_genre;
                            }
    
                            let xhr = new XMLHttpRequest();
                            xhr.onreadystatechange = () => {
                                if (xhr.readyState == 4 && xhr.status == 200) {
                                    
                                    let response = JSON.parse(xhr.response);
                                    console.log('here we go');
                                    console.log(response);
                                    let container = document.querySelector('.list__product');
                                    let container_parent = container.parentElement;

                                    /*if (response.genre_left_data == 'kfactory') {
                                        response.genre_left_data = 'K Factory';
                                    } else if (response.genre_right_data == 'kfactory') 
                                            response.genre_right_data = 'K Factory';*/

                                    // Change the url
                                    window.history.pushState(response.genre, 'Vega - '+response.genre, response.updated_url);

                                    // Update the buttons
                                    genre.innerText = response.genre;
                                    genre.classList.remove(response.genre_class_to_remove);
                                    genre.classList.add(response.genre_class);
                                    genre.dataset.genre = response.genre_data;

                                    genre_desc.innerText = response.genre_desc;

                                    genre_left.querySelector('.txt').innerText = response.genre_left;

                                    genre_left.className = genre_right.className = 'btn n_collection_btn';
                                    genre_left.classList.add(response.genre_left_class);
                                    genre_left.dataset.genre = response.genre_left_data;

                                    genre_right.querySelector('.txt').innerText = response.genre_right;
                                    genre_right.classList.add(response.genre_right_class);
                                    genre_right.dataset.genre = response.genre_right_data;

                                    genre_tab.classList.remove(response.genre_class_to_remove);
                                    genre_tab.classList.add(response.genre_class);

                                    container.remove();
                                    container_parent.innerHTML = response.content;
    
                                    if (response.no_albums === true) {
                                        setTimeout(() => {
                                            container_parent.querySelector('.list__product').firstElementChild.classList.add('is-visible');
                                        }, 1);
                                    } else {
                                        this.loadCatalogue();
                                        this.lazyLoading();
                                    }
                                }
                            };
                            xhr.open('GET', url, true);
                            xhr.send();
                        }
                    });
                });
            }
        }

        if (navigation_tab) {
            let n_s_btn = document.querySelectorAll('.n_s_genres_btn');

            if (n_s_btn) {
                n_s_btn.forEach((el) => {
                    el.addEventListener('click', (e) => {
                        e.preventDefault();

                        if (el.classList.contains('active')) {
                            return;
                        } else {
                            el.parentElement.parentElement.querySelector('.active').classList.remove('active');
                            el.classList.add('active');

                            let data_tab = el.dataset.tab;
                            let data_genre = document.querySelector('.n_m_genre').dataset.genre;

                            if (location.hostname == 'action.spirou.com') {
                                var url = '/vega/catalogue/genre/'+data_genre+'/2/'+data_tab+'/tab/'+data_tab;
                            } else {
                                var url = '/catalogue/genre/'+data_genre+'/2/'+data_tab+'/tab/'+data_tab;
                            }

                            let xhr = new XMLHttpRequest();
                            xhr.onreadystatechange = () => {
                                if (xhr.readyState == 4 && xhr.status == 200) {
                                    let response = JSON.parse(xhr.response);
                                    let container = document.querySelector('.list__product');
                                    let container_parent = container.parentElement;

                                    container.remove();
                                    container_parent.innerHTML = response.content;

                                    if (response.no_albums === true) {
                                        setTimeout(() => {
                                            container_parent.querySelector('.list__product').firstElementChild.classList.add('is-visible');
                                        }, 1);
                                    } else {
                                        this.loadCatalogue();
                                        this.lazyLoading();
                                    }
                                }
                            };
                            xhr.open('GET', url, true);
                            xhr.send();
                        }
                    });
                });
            }
        }
    }

    /**
     * Change color of text according to the background image RGB profile
     * Adapted from https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
     */
    textContrastImg() {
        let to_contrast_text = document.querySelectorAll('._contrast_text');

        if (to_contrast_text) {
            to_contrast_text.forEach((el) => {
                let global_parent = el.closest('section.serie');
                let image = global_parent.querySelector('figure img');
                let breadcrumbs = global_parent.querySelector('.breadcrumbs');

                // Trigger after the image has loaded
                let interval = setInterval(() => {
                    if (image.classList.contains('lazy-loaded')) {
                        const rgb = this.imageRGB(image);
                        const brightness = Math.round(((parseInt(rgb.r) * 299) + (parseInt(rgb.g) * 587) + (parseInt(rgb.b) * 114)) / 1000);
                        const textColour = (brightness > 125) ? '-black' : '-white';

                        el.classList.add(textColour);
                        breadcrumbs.classList.add(textColour);

                        clearInterval(interval);
                    }
                }, 150);
                
            });
        }
    }

    /**
     * Returns an rgb object of the RGB profile of the passed image object
     * from https://stackoverflow.com/questions/2541481/get-average-color-of-image-via-javascript
     * 
     * @param {Object} imgEl 
     */
    imageRGB(imgEl) {
        let block_size = 5, // visit the first 4 pixels
            default_rgb = {r:0, g:0, b:0}, // for non-supporting envs
            rgb = {r:0, g:0, b:0},
            canvas = document.createElement('canvas'),
            context = canvas.getContext && canvas.getContext('2d'),
            i = -4,
            data, width, height, length,
            count = 0;
        
        if (!context) {
            return default_rgb;
        }

        height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
        width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

        context.drawImage(imgEl, 0, 0);

        try {
            data = context.getImageData(0, 0, width, height);
        } catch(e) {
            /* security error, img on diff domain */
            return default_rgb;
        }

        length = data.data.length;

        while ((i += block_size * 4) < length) {
            ++count;
            rgb.r += data.data[i];
            rgb.g += data.data[i+1];
            rgb.b += data.data[i+2];
        }

        // ~~ used to floor values
        rgb.r = ~~(rgb.r/count);
        rgb.g = ~~(rgb.g/count);
        rgb.b = ~~(rgb.b/count);

        return rgb;
    }

    /**
     * Init sidebar
     */
    sidebar() {
        let triggerSidebar = document.querySelectorAll('[data-sidebar]');

        if (triggerSidebar) {
            triggerSidebar.forEach((el) => {
                el.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    let id = el.dataset.sidebar;
                    let target = document.querySelector(`#${id}`);

                    if (target) {
                        let close = target.querySelector('.close-sidebar');
                        let product_list = target.querySelector('.list__product');
                        let target_input = target.querySelector('._sIn');

                        if (target_input) {
                            setTimeout(() => { // Timeout a little bit longer than animation so we are sure element is visible
                                target_input.focus();
                            }, 450);
                        }

                        HTML.classList.add('show-sidebar');

                        if (!target.classList.contains('show')) {
                            target.classList.add('show');

                            // Ajax load list only if the sidebad is opened to gain in first load speed
                            if (product_list && !product_list.classList.contains('-loaded')) {
                                product_list.classList.add('-loaded');

                                if (location.hostname == 'action.spirou.com') {
                                    var url = '/vega/actions/sidebar/series_list';
                                } else {
                                    var url = '/actions/sidebar/series_list';
                                }
        
                                let xhr = new XMLHttpRequest();
                                xhr.onreadystatechange = () => {
                                    if (xhr.readyState == 4 && xhr.status == 200) {
                                        let response = JSON.parse(xhr.response);
                                        let container = product_list;
                                        let container_parent = container.parentElement;
                                        
                                        container.remove();
                                        container_parent.innerHTML = response.content;

                                        setTimeout(() => {
                                            this.loadCatalogue();
                                            this.lazyLoading();
                                        }, 550);
                                    }
                                };
                                xhr.open('GET', url, true);
                                xhr.send();
                            }
                        }

                        if (id === 'cartbar' && !target.dataset.listener)  {
                            target.style.zIndex = 999999;
                            target.dataset.listener = 'true';
                            try {
                                let cartbar_footer = target.querySelector('.sidebar__footer--content');
                                if (cartbar_footer) {
                                    let iframe = document.createElement('iframe');
                                    iframe.id = 'AV_widget_iframe';
                                    iframe.frameBorder = 0;
                                    iframe.width = '100%';
                                    iframe.height = '175';
                                    iframe.src = '//cl.avis-verifies.com/fr/cache/7/c/8/7c812eed-a810-5d64-e91f-5265c97ce950/widget4/7c812eed-a810-5d64-e91f-5265c97ce950horizontal_index.html';

                                    cartbar_footer.appendChild(iframe);
                                }
                            } catch(err) {
                                console.log(err);
                            }
                        }

                        if (close) {
                            function closeSidebar(e) {
                                e.preventDefault();
    
                                HTML.classList.remove('show-sidebar');
                                target.classList.remove('show');
                                close.removeEventListener('click', closeSidebar);
                                HTML.removeEventListener('click', clickBody);
                            }
    
                            function clickBody(e) {
                                if (!e.target.classList.contains('sidebar') && !e.target.closest('.sidebar')) {
                                    close.dispatchEvent(new Event('click'));
                                }
                            }

                            close.addEventListener('click', closeSidebar);
                            HTML.addEventListener('click', clickBody);
                        }
                    }
                });
            });
        }
    }

    /**
     * Init recherche
     */
    searchAutoComplete() {
        let search_inputs = document.querySelectorAll('._sIn');

        if (search_inputs) {
            search_inputs.forEach((el) => {
                let timeout = null;
                let autocomplete = el.parentElement.parentElement.querySelector('._sIn_res');
                let _mHeight = Number(window.innerHeight - autocomplete.getBoundingClientRect().top - el.offsetHeight);

                if (_mHeight > el.offsetHeight) {
                    autocomplete.style.maxHeight = _mHeight+'px';
                    autocomplete.style.overflowY = 'scroll';
                }

                ['focus', 'focusout', 'scroll', 'resize', 'input'].forEach((et) => {
                    el.addEventListener(et, (e) => {
                        switch (et) {
                            case 'focus':
                                if (el.value.length >= 3) {
                                    el.parentElement.parentElement.classList.add('-focused');
                                } else {
                                    el.parentElement.parentElement.classList.remove('-focused');
                                }
                                break;
                            case 'focusout':
                                el.parentElement.parentElement.classList.remove('-focused');
                                break;
                            case 'resize':
                                let _tDiff = Number(window.innerHeight - autocomplete.getBoundingClientRect().top - el.offsetHeight);
                                _mHeight = Number(_tDiff);

                                if (_mHeight > el.offsetHeight) {
                                    autocomplete.style.maxHeight = _mHeight+'px';
                                    autocomplete.style.overflowY = 'scroll';
                                }
                                break;
                            case 'input':
                                if (el.value.length >= 3) {
                                    el.parentElement.parentElement.classList.add('-focused');
                                } else {
                                    el.parentElement.parentElement.classList.remove('-focused');
                                }
                                break;
                        }
                    });
                });

                el.addEventListener('input', (e) => {
                    e.preventDefault();

                    let data = String(el.value);

                    // Only check if the data is longer than 3 chars
                    if (el.value.length >= 3) {
                        clearTimeout(timeout);

                        // Timeout so we don't trigger while user is typing (avoid ajax overcharge)
                        timeout = setTimeout(() => {
                            if (location.hostname == 'action.spirou.com') {
                                var url = '/vega/search_all/'+data;
                            } else {
                                var url = '/search_all/'+data;
                            }

                            let xhr = new XMLHttpRequest();
                            xhr.onreadystatechange = () => {
                                if (xhr.readyState == 4 && xhr.status == 200) {
                                    let response = JSON.parse(xhr.response);

                                    autocomplete.innerHTML = '';
                                    
                                    if (response.empty === true) {
                                        autocomplete.innerHTML = '';
                                    } else {
                                        // Genres pills
                                        if (response.genres) {
                                            let group_pills = document.createElement('span');
                                            group_pills.classList.add('group', 'group--pills');

                                            response.genres.forEach((el) => {
                                                let pill = document.createElement('span');
                                                pill.classList.add('pills');
                                                pill.innerHTML = '<a href="'+String(el.slug)+'" class="'+String(el.genre_class)+'">'+String(el.genre)+'</a>';

                                                group_pills.appendChild(pill);
                                            });

                                            autocomplete.appendChild(group_pills);
                                        }

                                        // then albums
                                        if (response.albums) {
                                            autocomplete.innerHTML += '<span class="group group--head">Album(s)</span>';
                                            response.albums.forEach((el) => {
                                                autocomplete.innerHTML += '<span class="group group__item '+String(el.genre_class)+'"><a href="'+String(el.slug)+'" data-highlight="'+String(el.titre)+'">'+String(el.titre)+'</a></span>'
                                            });
                                        }

                                        // then series
                                        if (response.series) {
                                            autocomplete.innerHTML += '<span class="group group--head">Série(s)</span>';
                                            response.series.forEach((el) => {
                                                autocomplete.innerHTML += '<span class="group group__item '+String(el.genre_class)+'"><a href="'+String(el.slug)+'" data-highlight="'+String(el.titre)+'">'+String(el.titre)+'</a></span>'
                                            });
                                        }

                                        // Highlight what we typed if it's there
                                        let groups = autocomplete.querySelectorAll('.group__item a');

                                        if (groups) {
                                            groups.forEach((el) => {
                                                const el_val = String(el.dataset.highlight);
                                                const regex = new RegExp(String(data), 'i');
                                                const data_ok = el_val.match(regex);

                                                if (data_ok != null) {
                                                    el.value = el_val.replace(data_ok, '<span class="highlight">'+data_ok[0]+'</span>');
                                                    el.innerHTML = String(el.value);
                                                }
                                            });
                                        }
                                    }
                                    
                                }
                            };
                            xhr.open('GET', url, true);
                            xhr.send();
                        }, 200);
                    } else {
                        setTimeout(() => {
                            autocomplete.innerHTML = '';
                        }, 200);
                    }
                });

                let form_search = el.parentElement;

                if (form_search) {
                    form_search.addEventListener('submit', (e) => {
                        e.preventDefault();

                        if (location.hostname == 'action.spirou.com') {
                            var url = '/vega/recherche/'+el.value;
                        } else {
                            var url = '/recherche/'+el.value;
                        }

                        window.location.href = url;
                    });
                }
            });
        }
    }

    /**
     * Init searchbar on search page
     */
    searchPage() {
        let search_form = document.querySelector('.form-advanced-search');

        if (search_form) {
            let search_input = document.querySelector('._sAdv');

            search_form.addEventListener('submit', (e) => {
                e.preventDefault();

                let data = search_input.value;
                let active_filters = document.querySelectorAll('._fAni');

                if (data && active_filters.length > 0) {
                    // Filters and search input has data
                    this._searchInputOrder(data, active_filters);
                } else if (data) {
                    // Only the search input has data in it
                    this._searchInput(data);
                } else if (active_filters.length > 0) {
                    // Filters active only
                    this._searchOrder(active_filters);
                } else {
                    // We do nothing if there's nothing to do
                    let reset = document.querySelector('._cSer');
                    reset.dispatchEvent(new Event('click'));
                    return;
                }
            });

            let cancel_search = document.querySelector('._cSer');

            if (cancel_search) {
                cancel_search.addEventListener('click', (e) => {
                    e.preventDefault();

                    let loadmore_btn = document.querySelector('._mLoad');

                    if (location.hostname == 'action.spirou.com') {
                        var url_cancel = '/vega/recherche_reset/';
                    } else {
                        var url_cancel = '/recherche_reset/';
                    }

                    let xhr = new XMLHttpRequest();
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState == 4 && xhr.status == 200) {
                            search_input.value = '';
                            loadmore_btn.parentElement.classList.remove('-hide');

                            delete loadmore_btn.dataset.searchquery;

                            let active_filters = document.querySelectorAll('._fAni');
                            if (active_filters) {
                                active_filters.forEach((el) => {
                                    el.classList.remove('-show');

                                    setTimeout(() => {
                                        el.remove();
                                    }, 250);
                                });
                            }

                            let response = JSON.parse(xhr.response);
                            let container = document.querySelector('.list__product');
                            let container_parent = container.parentElement;

                            container.remove();
                            container_parent.innerHTML = response.content;

                            this.loadCatalogue();
                            this.lazyLoading();
                        }
                    };
                    xhr.open('GET', url_cancel, true);
                    xhr.send();
                });
            }
        }
    }

    /**
     * Select Options
     */
    selectOptions() {
        let selectEls = document.querySelectorAll('.select');

        if (selectEls) {
            selectEls.forEach((el) => {
				let choices = new Choices(el, {
                    searchEnabled: false,
                    shouldSort: false,
                    itemSelectText: '',
                    callbackOnInit: () => {
                        let filter_active_container = document.querySelector('._fAn');

                        el.addEventListener('change', (e) => {
                            if (el.value) {
                                let new_filter = document.createElement('span');
                                new_filter.classList.add('_fAni');

                                switch (el.value) {
                                    case 'datemore':
                                        let dateless = filter_active_container.querySelector('[data-querymodifier="dateless"]');

                                        if (dateless) {
                                            dateless.classList.remove('-show');
                                            setTimeout(() => {
                                                dateless.remove();
                                            }, 250);
                                        }

                                        new_filter.innerText = String(el.innerText);
                                        break;
                                    case 'dateless':
                                        let datemore = filter_active_container.querySelector('[data-querymodifier="datemore"]');

                                        if (datemore) {
                                            datemore.classList.remove('-show');
                                            setTimeout(() => {
                                                datemore.remove();
                                            }, 250);
                                        }
                                        
                                        new_filter.innerText = String(el.innerText);
                                        break;
                                    default:
                                        new_filter.innerText = String(el.value);
                                        break;
                                }
                                
                                new_filter.dataset.querymodifier = String(el.value);
                                
                                let active_filter_check = document.querySelector('[data-querymodifier="'+String(el.value)+'"]');
    
                                if (!active_filter_check) {
                                    filter_active_container.appendChild(new_filter);
    
                                    setTimeout(() => {
                                        new_filter.classList.add('-show');
                                        this.selectOptionsOrder();

                                        new_filter.addEventListener('click', (evt) => {
                                            new_filter.classList.remove('-show');
                                            new_filter.classList.add('-nowidth');

                                            setTimeout(() => {
                                                new_filter.remove();
                                                this.selectOptionsOrder();
                                            }, 250);
                                        });
                                    }, 250);
                                }
                            }
                        });
                    }
                });
            });
        }
    }

    /**
     * Order items from select options
     */
    selectOptionsOrder() {
        let active_filters = document.querySelectorAll('._fAni');
        
        if (active_filters) {
            let search_input = document.querySelector('._sAdv');
            let data = search_input.value;

            if (data && active_filters.length > 0) {
                // Filters and search input has data
                this._searchInputOrder(data, active_filters);
            } else if (data) {
                // Only the search input has data in it
                this._searchInput(data);
            } else if (active_filters.length > 0) {
                // Filters active only
                this._searchOrder(active_filters);
            } else {
                // We do nothing if there's nothing to do
                let reset = document.querySelector('._cSer');
                reset.dispatchEvent(new Event('click'));
                return;
            }
        }
    }

    /**
     * Load more items (ajax)
     */
    loadMore() {
        let more_btn = document.querySelectorAll('._mLoad');

        if (more_btn) {
            more_btn.forEach((el) => {
                let type = String(el.dataset.type);

                el.addEventListener('click', (e) => {
                    e.preventDefault();

                    if (type === 'albums') {
                        let offset = Number(document.querySelectorAll('._album').length);
                        if (location.hostname == 'action.spirou.com') {
                            if (el.dataset.searchquery) {
                                var url = '/vega/load_more/'+type+'/'+offset+'/'+String(el.dataset.searchquery);
                            } else {
                                var url = '/vega/load_more/'+type+'/'+offset;
                            }
                        } else {
                            if (el.dataset.searchquery) {
                                var url = '/load_more/'+type+'/'+offset+'/'+String(el.dataset.searchquery);
                            } else {
                                var url = '/load_more/'+type+'/'+offset;
                            }
                        }

                        let xhr = new XMLHttpRequest();
                        xhr.onreadystatechange = () => {
                            if (xhr.readyState == 4 && xhr.status == 200) {
                                let response = JSON.parse(xhr.response);
                                let container = document.querySelector('.list__product');

                                if (response.remove_loadmore === true) {
                                    el.parentElement.classList.add('-hide');
                                }

                                container.innerHTML += response.content;

                                this.loadCatalogue();
                                this.lazyLoading();
                            }
                        };
                        xhr.open('GET', url, true);
                        xhr.send();
                    }
                });
            });
        }
    }

    /**
     * Read more button on albums text
     */
    readMore() {
        let read_more_btns = document.querySelectorAll('._rMore');

        if (read_more_btns) {
            read_more_btns.forEach((el) => {
                el.addEventListener('click', (e) => {
                    e.preventDefault();

                    let text = el.parentElement.querySelector('._trunc');
                    
                    if (text) {
                        text.classList.add('untrunc');
                    }
                });
            });
        }
    }

    /**
     * Init add to cart buttons
     */
    initAddToCart() {
        let add_to_cart_btns = document.querySelectorAll('._addtocart');

        if (add_to_cart_btns) {
            add_to_cart_btns.forEach((el) => {
                el.addEventListener('click', (e) => {
                    e.preventDefault();

                    if (location.hostname == 'action.spirou.com') {
                        var url = '/vega/add_to_cart';
                    } else {
                        var url = '/add_to_cart';
                    }

                    let xhr = new XMLHttpRequest();
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState == 4 && xhr.status == 200) {
                            let response = JSON.parse(xhr.response);

                            if (!response.error) {
                                if (!document.querySelector('.pills-cart')) {
                                    let add_cart_pill = document.createElement('div');
                                    add_cart_pill.classList.add('pills-cart');
                                    let add_cart_pill_qty = document.createElement('span');
                                    add_cart_pill_qty.innerHTML = '+1';
    
                                    add_cart_pill.appendChild(add_cart_pill_qty);
                                    document.body.prepend(add_cart_pill);
                                }

                                let pos = {
                                    cursor_x: e.clientX,
                                    cursor_y: e.clientY
                                };

                                let cart_pill = document.querySelector('.pills-cart');
                                cart_pill.style.top = (pos.cursor_y - 5)+'px';
                                cart_pill.style.left = (pos.cursor_x - 5)+'px';

                                let cart_pos_data = document.querySelector('.cart-block').getBoundingClientRect();
                                let cart_pos = {
                                    pos_x: cart_pos_data.left + window.scrollX,
                                    pos_y: cart_pos_data.top + window.scrollY
                                };
                                gsap.to(cart_pill, {duration: 0.5, top: cart_pos.pos_y, left: cart_pos.pos_x, ease: "cubic.inOut" });

                                setTimeout(() => {
                                    cart_pill.remove();

                                    if (!document.querySelector('.badge-cart')) {
                                        let badge_container = document.querySelector('.cart-block');
                                        let badge = document.createElement('div');
                                        badge.classList.add('badge-cart');
                                        badge.innerHTML = '<span>'+response.cart.items+'</span>';
    
                                        badge_container.appendChild(badge);
                                    }
                                    
                                    this.updateCart(response.album, response.cart);
                                }, 501);
                            }
                        }
                    };
                    xhr.open('POST', url, true);
                    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                    xhr.send('item='+encodeURIComponent(el.dataset.item));
                });
            });
        }
    }

    /**
     * Init quantty buttons in the cart aside div
     * 
     * @param  {String} ean
     */
    initCartQtyBtns(ean) {
        if (ean) {
            var qty_btns = document.querySelectorAll('._qtyMod[data-ean="'+ean+'"]');
            var rm_btns = document.querySelectorAll('._rmCart[data-ean="'+ean+'"]');
        } else {
            var qty_btns = document.querySelectorAll('._qtyMod');
            var rm_btns = document.querySelectorAll('._rmCart');
        }

        if (qty_btns) {
            qty_btns.forEach((el) => {
                el.addEventListener('click', (e) => {
                    e.preventDefault();
                    
                    switch (el.dataset.qty) {
                        case 'qtyless':
                            if (location.hostname == 'action.spirou.com') {
                                var url = '/vega/remove_from_cart';
                            } else {
                                var url = '/remove_from_cart';
                            }
                            var qty = -1;
                            break;
                        case 'qtymore':
                            if (location.hostname == 'action.spirou.com') {
                                var url = '/vega/add_to_cart';
                            } else {
                                var url = '/add_to_cart';
                            }
                            var qty = 1;
                            break;
                        default:
                            break;
                    }

                    let xhr = new XMLHttpRequest();
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState == 4 && xhr.status == 200) {
                            let response = JSON.parse(xhr.response);

                            if (!response.error && response.album && response.cart) {
                                this.updateCart(response.album, response.cart);
                            } else {
                                console.log('Error');
                            }
                        }
                    };
                    xhr.open('POST', url, true);
                    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                    xhr.send('item='+encodeURIComponent(el.dataset.item)+'&qty='+encodeURIComponent(qty));
                });
            });
        }

        if (rm_btns) {
            rm_btns.forEach((el) => {
                el.addEventListener('click', (e) => {
                    e.preventDefault();

                    if (location.hostname == 'action.spirou.com') {
                        var url = '/vega/remove_from_cart';
                    } else {
                        var url = '/remove_from_cart';
                    }

                    let xhr = new XMLHttpRequest();
                    xhr.onreadystatechange = () => {
                        if (xhr.readyState == 4 && xhr.status == 200) {
                            let response = JSON.parse(xhr.response);

                            if (!response.error  && response.album && response.cart) {
                                this.updateCart(response.album, response.cart);
                            }
                        }
                    };
                    xhr.open('POST', url, true);
                    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                    xhr.send('item='+encodeURIComponent(el.dataset.item)+'&void='+encodeURIComponent('true'));
                });
            });
        }
    }

    /**
     * Update cart content
     * 
     * @param  {Object} album
     * @param  {Object} cart
     */
    updateCart(album, cart) {
        // update cart total quantity and price
        let cart_qty = document.querySelector('h2 ._qty');
        if (cart_qty) {
            cart_qty.innerText = cart.items;
        }

        let cart_total = document.querySelector('._cTotal');
        if (cart_total) {
            cart_total.innerText = cart.total;
        }

        let cart_header_qty = document.querySelector('.badge-cart span');
        if (cart_header_qty) {
            cart_header_qty.innerText = cart.items;
        }

        // update album line
        let album_qty = document.querySelector('._qtyMod[data-ean="'+album.ean+'"]');
        let cart_order_store = document.querySelector('.cart_order input[name="batch['+album.ean+']"]');
        
        if (album_qty) {
            // Update quantity or remove item if quantity = 0
            album_qty = album_qty.nextElementSibling;

            if (album.quantity) {
                album_qty.value = album.quantity;
                cart_order_store.value = album.quantity;

            } else {
                let album_line = document.querySelector('.list__cart--item[data-ean="'+album.ean+'"]');
                
                if (album_line) {
                    gsap.to(album_line, {duration: 0.25, autoAlpha: 0, display: 'none', ease: "cubic.inOut" });

                    setTimeout(() => {
                        album_line.remove();
                        cart_order_store.remove();
                    }, 250);
                }
            }
        } else {
            // Add item to cart cause it doesn't exist yet
            // First check if cart is empty or not
            let empty_cart = document.querySelector('h2.no-alb');
            // Then create the container for the items
            if (empty_cart && cart.items > 0) {
                // Parent container
                let container = empty_cart.parentElement;

                // Container with Panier (0) title
                let cart_title_container = document.createElement('div');
                cart_title_container.classList.add('wysiwyg');

                // Actual title
                let cart_title = document.createElement('h2');
                cart_title.classList.add('-uppercase', '-darkred');
                cart_title.innerHTML = 'Votre panier (<span class="_qty">'+cart.items+'</span>)';

                // Container for the items in cart
                let list_cart_container = document.createElement('div');
                list_cart_container.classList.add('list', 'list__cart');

                // Cart action with total and button
                let cart_total_container = document.createElement('div');
                cart_total_container.classList.add('cart-total');
                cart_total_container.innerHTML = 
                    '<p><strong>Total:</strong></p>' +
                    '<div class="total">' +
                        '<p><strong class="-darkred"><span class="_cTotal">'+cart.total+'</span>€</strong></p>' +
                        '<small>+ frais d\'envoi</small>' +
                    '</div>' +
                    '<form class="cart_order" action="https://9e-store.fr/cart/" method="post" target="_blank">' +
                        '<input type="hidden" name="_method" value="PATCH" />' +
                        '<button class="btn -darkred">Finaliser ma commande</button>' +
                    '</form>'
                ;

                empty_cart.remove();
                cart_title_container.appendChild(cart_title);
                container.appendChild(cart_title_container);
                container.appendChild(list_cart_container);
                container.appendChild(cart_total_container);
            }

            var list_cart = document.querySelector('.list__cart');
            if (list_cart) {
                let item = document.createElement('div');
                item.classList.add('list__cart--item');
                item.dataset.item = album.encrypted_slug;
                item.dataset.ean = album.ean;
                if (album.bda_couv) {
                    var image_url = album.bda_couv;
                } else if (album.couverture) {
                    var image_url = base_url+'assets-upload/'+album.couverture;
                } else {
                    var image_url = base_url+'assets/dist/couve_bientot_dispob.b23c097a.jpg';
                }
                item.innerHTML = 
                    '<figure>' +
                        '<div class="inner">' +
                            '<img class="img-temp-lazy to_del_lazy" src="data:image/gif;base64,R0lGODlh0gAiAYAAAOrq6gAAACH5BAAAAAAALAAAAADSACIBAAL/hI+py+0Po5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6zvf+DwwKh8Si8YhMKpfMpvMJjUqn1Kr1is1qt9yu9wsOi8fksvmMTqvX7Lb7DY/L5/S6/Y7P6/f8vv8PGCg4SFhoeIiYqLjI2Oj4CBkpOUlZaXmJmam5ydnp+QkaKjpKWmp6ipqqusra6voKGys7S1tre4ubq7vL2+v7CxwsPExcbHyMnKy8zNzs/AwdLT1NXW19jZ2tvc3d7f0NHi4+Tl5ufo6err7O3u7+Dh8vP09fb3+Pn6+/z9/v/w8woMCBBAsaPIgwocKFDBs6fAgxosSJFCtavIgxo8aNUBw7evwIMqTIkSRLmjyJMqXKlSxbunwJM6bMmTRr2ryJM6fOnTx7+vwJNKjQoUSLGj2KNKnSpUybOn0KNarUqVSrWr2KNavWrVy7ev0KVlYBADs=">' +
                            '<img class="js-lazy" src="data:image/gif;base64,R0lGODlh0gAiAYAAAOrq6gAAACH5BAAAAAAALAAAAADSACIBAAL/hI+py+0Po5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6zvf+DwwKh8Si8YhMKpfMpvMJjUqn1Kr1is1qt9yu9wsOi8fksvmMTqvX7Lb7DY/L5/S6/Y7P6/f8vv8PGCg4SFhoeIiYqLjI2Oj4CBkpOUlZaXmJmam5ydnp+QkaKjpKWmp6ipqqusra6voKGys7S1tre4ubq7vL2+v7CxwsPExcbHyMnKy8zNzs/AwdLT1NXW19jZ2tvc3d7f0NHi4+Tl5ufo6err7O3u7+Dh8vP09fb3+Pn6+/z9/v/w8woMCBBAsaPIgwocKFDBs6fAgxosSJFCtavIgxo8aNUBw7evwIMqTIkSRLmjyJMqXKlSxbunwJM6bMmTRr2ryJM6fOnTx7+vwJNKjQoUSLGj2KNKnSpUybOn0KNarUqVSrWr2KNavWrVy7ev0KVlYBADs=" data-src="'+image_url+'" alt="'+album.titre+'">' +
                        '</div>' +
                    '</figure>' +
                    '<div class="content">' +
                        '<div class="infos">' +
                            '<h4>'+album.titre+'</h4>' +
                        '</div>' +
                        '<div class="quantity">' +
                            '<div class="inner">' +
                                '<button class="_qtyMod" data-qty="qtyless" data-item="'+album.encrypted_slug+'" data-ean="'+album.ean+'">' +
                                    '<span>-</span>' +
                                '</button>' +
                                '<input type="text" name="qty" value="'+album.quantity+'" disabled>' +
                                '<button class="_qtyMod" data-qty="qtymore" data-item="'+album.encrypted_slug+'" data-ean="'+album.ean+'">' +
                                    '<span>+</span>' +
                                '</button>' +
                            '</div>' +
                        '</div>' +
                        '<div class="price">' +
                            '<span>'+album.prix+'€</span>' +
                        '</div>' +
                        '<button class="remove _rmCart" data-item="'+album.encrypted_slug+'" data-ean="'+album.ean+'">' +
                            '<i class="icon icon--delete ">' +
                                '<!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->' +
                                '<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve">' +
                                    '<style type="text/css">' +
                                        '.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}' +
                                    '</style>' +
                                    '<path class="st0" d="M243.8,56.3h-46.9l-13.4-18.8h-67l-13.4,18.8H56.3V75h187.5L243.8,56.3z M87,238.4c0.3,13.5,11.5,24.3,25,24.1h76h0c13.5,0.2,24.7-10.6,25-24.1l12-144.6H75L87,238.4z"></path>' +
                                '</svg>' +
                            '</i>' +
                        '</button>'
                ;

                list_cart.appendChild(item);

                this.lazyLoading();
                this.initCartQtyBtns(album.ean);
            }

            var cart_order_store_container = document.querySelector('.cart_order');
            if (cart_order_store_container) {
                let input = document.createElement('input');
                input.type = 'hidden';
                input.name = 'batch['+album.ean+']';
                input.value = album.quantity;

                cart_order_store_container.appendChild(input);
            }
        }

        // Re-template empty cart
        if (cart.items === 0) {
            let container = document.querySelector('.list__cart').parentElement;
            
            document.querySelector('.list__cart').remove();
            cart_header_qty.parentElement.remove();
            container.innerHTML = '<h2 class="no-alb u-tac js-reveal is-visible"><span>Votre panier est vide <span class="emoji">(っ˘̩╭╮˘̩)っ</span></span></h2>';
        }
    }

    /**
     * The search action that returns the needed information to display the search results from the input
     * 
     * @param {String} data 
     */
    _searchInput(data) {
        let loadmore_btn = document.querySelector('._mLoad');

        if (location.hostname == 'action.spirou.com') {
            var url = '/vega/search_all/'+data+'/list';
        } else {
            var url = '/search_all/'+data+'/list';
        }

        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState == 4 && xhr.status == 200) {
                if (loadmore_btn) {
                    loadmore_btn.parentElement.classList.add('-hide');
                    loadmore_btn.dataset.searchquery = data;
                }

                let response = JSON.parse(xhr.response);
                let container = document.querySelector('.list__product');
                let container_parent = container.parentElement;

                container.remove();
                container_parent.innerHTML = response.albumContent;

                if (response.albumLoadmore === true) {
                    loadmore_btn.parentElement.classList.remove('-hide');
                }

                if (response.empty === true) {
                    setTimeout(() => {
                        container_parent.querySelector('.list__product').firstElementChild.classList.add('is-visible');
                    }, 1);
                } else {
                    this.loadCatalogue();
                    this.lazyLoading();
                }
            }
        };
        xhr.open('GET', url, true);
        xhr.send();
    }

    /**
     * Search order by filters action
     * 
     * @param {Object} active_filters 
     */
    _searchOrder(active_filters) {
        if (active_filters) {
            let querymodifier = '';
        
            active_filters.forEach((el) => {
                querymodifier += el.dataset.querymodifier+'xX000Xx';
            });

            if (querymodifier.length > 0) {
                this._searchOrderedAction(querymodifier);
            } else {
                // Empty filters, so we only take the searchbar input, else reset search
                let search_input = document.querySelector('._sAdv').value;
                
                if (search_input) {
                    if (search_input.length > 0) {
                        // Fallback on the searchInput only if no filters
                        let data = search_input;
                        this._searchInput(data);
                    } else {
                        let reset = document.querySelector('._cSer');
                        reset.dispatchEvent(new Event('click'));
                    }
                } else {
                    let reset = document.querySelector('._cSer');
                    reset.dispatchEvent(new Event('click'));
                }
            }
        }
    }

    /**
     * Ajax call for the multi words & params query
     * 
     * @param {String} querymodifier 
     */
    _searchOrderedAction(querymodifier) {
        if (querymodifier) {
            let loadmore_btn = document.querySelector('._mLoad');

            querymodifier = querymodifier.slice(0, -7);
            if (location.hostname == 'action.spirou.com') {
                var url = '/vega/search_ordered/'+String(querymodifier);
            } else {
                var url = '/search_ordered/'+String(querymodifier);
            }

            let xhr = new XMLHttpRequest();
            xhr.onreadystatechange = () => {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    let response = JSON.parse(xhr.response);
                    
                    if (loadmore_btn) {
                        loadmore_btn.parentElement.classList.add('-hide');
                        loadmore_btn.dataset.searchquery = response.ordered_query;
                    }

                    let container = document.querySelector('.list__product');
                    let container_parent = container.parentElement;

                    container.remove();
                    container_parent.innerHTML = response.albumContent;

                    if (response.albumLoadmore === true) {
                        loadmore_btn.parentElement.classList.remove('-hide');
                    }

                    if (response.empty === true) {
                        setTimeout(() => {
                            container_parent.querySelector('.list__product').firstElementChild.classList.add('is-visible');
                        }, 1);
                    } else {
                        this.loadCatalogue();
                        this.lazyLoading();
                    }
                }
            };
            xhr.open('GET', url, true);
            xhr.send();
        }
    }

    /**
     * Combined search action using filters AND search input
     * 
     * @param {String} data 
     * @param {Object} active_filters 
     */
    _searchInputOrder(data, active_filters) {
        if (data && active_filters) {
            let querymodifier = '';
        
            active_filters.forEach((el) => {
                querymodifier += el.dataset.querymodifier+'xX000Xx';
            });

            querymodifier += String(data)+'xX000Xx';

            if (querymodifier.length > 0) {
                this._searchOrderedAction(querymodifier);
            }
        }
    }

    /*-------------------------------------------------------------------------*\
    _orderCart() {
        let submit = document.querySelector('._order-cart');

        if (submit) {
            submit.addEventListener('click', (e) => {
                e.preventDefault();

                let products = document.querySelectorAll('.list__cart--item');
                let batch_string = '';
                if (products) {
                    products.forEach((el) => {
                        let ean = el.dataset.ean;
                        let qty = el.querySelector('.quantity input[name="qty"]').value;

                        batch_string += encodeURIComponent('batch['+ean+']')+'='+qty+'&';
                    });
                }
                batch_string = batch_string.substring(0, batch_string.length - 1);

                let url = 'https://9e-store.fr/cart/'
                let xhr = new XMLHttpRequest();
                xhr.onreadystatechange = () => {
                    if (xhr.readyState == 4 && xhr.status == 200) {
                        let response = JSON.parse(xhr.response);
                        
                        console.log(response);
                    }
                };
                xhr.open('POST', url, true);
                xhr.send('_method=PATCH&'+batch_string);
            });
        }
    }
    \*-------------------------------------------------------------------------*/
}

window.addEventListener('load', function() {
    new App();
});

/**
 * Open the cookie management modal
 * 
 * @param {*} tac_open_modal 
 */
window.tacM = (tac_open_modal) => {
    // First, bottom banner animation
    let bot_ban = document.querySelector('#tarteaucitronAlertBig');

    if (bot_ban) {
        gsap.to(bot_ban, {delay: .25, autoAlpha: 1, y: -20, duration: 1, ease: 'power4.inOut'});
    }

    // Init modal objects to animate
    let tac_bak = document.querySelector('#tarteaucitronBack');
    let tac_modak = document.querySelector('div#tarteaucitron');
    let tac_clak = document.querySelector('#tarteaucitronClosePanel');

    if (tac_bak && tac_modak && tac_clak) {
        tac_bak.addEventListener('click', (e) => {
            gsap.to(tac_bak, {autoAlpha: 0, duration: .25, display: 'none', ease: 'power4.inOut'});
            gsap.to(tac_modak, {autoAlpha: 0, y: 0, duration: .25, display: 'none', ease: 'power4.inOut'});
        });

        tac_clak.addEventListener('click', (e) => {
            gsap.to(tac_bak, {autoAlpha: 0, duration: .25, display: 'none', ease: 'power4.inOut'});
            gsap.to(tac_modak, {autoAlpha: 0, y: 0, duration: .25, display: 'none', ease: 'power4.inOut'});
        });

        if (tac_open_modal) {
            tac_open_modal.forEach((el) => {
                el.addEventListener('click', (e) => {
                    gsap.to(tac_bak, {autoAlpha: 1, duration: .25, ease: 'power4.inOut'});
                    gsap.to(tac_modak, {autoAlpha: 1, y: -20, duration: .25, ease: 'power4.inOut'});
                });
            });
        }
    }
}

/**
 * Close iframe
 * 
 * @param {*} el 
 */
 window.closeIframe = (el) => {
    let iframeModale = document.querySelector('.iframe__modal');
    iframeModale.classList.remove('show');
    iframeModale.classList.add('hide');
    setTimeout(() => {
        iframeModale.remove();
    }, 250);
}


/* Script bash pour automator qui utilise imagemagik pour convertir pdf en jpg et en les compressants
for f in "$@"
do
    fname="${f%.*}.jpg"
    convert -density 300 -quality 70 "$f" "$fname"
done
*/